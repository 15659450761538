import { defineStore } from "pinia";

export const useModalError = defineStore("modal-error", {
	state: (): {
		visibility: boolean;
		message: string;
		errorMessage: boolean;
	} => ({
		visibility: false,
		message: "",
		errorMessage: false,
	}),
	actions: {
		open(textMessage: string, error: boolean = false): void {
			this.visibility = true;
			this.message = textMessage;
			this.errorMessage = error;
		},
		close(): void {
			this.visibility = false;
			this.message = "";
			this.errorMessage = false;
		},
	},
	getters: {
		getVisibility(): boolean {
			return this.visibility;
		},
		getMessage(): string {
			return this.message;
		},
		getIsErrorMessage(): boolean {
			return this.errorMessage;
		},
	},
});
