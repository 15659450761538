<template>
	<NuxtLayout>
		<NuxtLoadingIndicator :color="'var(--yellow)'" />

		<NuxtPage />
	</NuxtLayout>
</template>

<script setup lang="ts">
	const {
		wsStore: { wsInitial },
	} = useStores();

	onMounted(async () => {
		await wsInitial();
	});
</script>
