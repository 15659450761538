import { type IEvent } from "@/assets/ts/global/interfaces/event/IEvent";
import { type IEventAttributes } from "@/assets/ts/global/interfaces/event/IEventAttributes";

export function hasAnyEventAttribute(event: IEvent): boolean {
	const attributes: Array<keyof IEventAttributes> = ["culture", "farmer", "warehouse", "fca", "fca_nds", "cpt", "cpt_nds", "volume", "client", "route", "tarif", "qualities"];

	for (const attr of attributes) {
		if (event.attributes[attr] !== null && event.attributes[attr] !== undefined) {
			return true;
		}
	}

	return false;
}
