import { useOFetch } from "~/composables/useOFetch";
import type { IApiResponseMulti } from "@/assets/ts/entities/api/interfaces/IApiResponseMulti";
import { api } from "~/api/api";
import type { IApiResponsePayload } from "@/assets/ts/entities/api/interfaces/IApiResponsePayload";
import { filterAcceptedEvents } from "~/utils/filter-accepted-events";
import type { IFetchError } from "ofetch";
import { useIsActiveAgent } from "~/composables/useIsActiveAgent";
import { saveToCache } from "~/utils/save-to-cache";

export async function fetchMultiData(agentId: string = ""): Promise<{ error: Ref<string> }> {
	const {
		eventsStore: { setEvents },
		messagesStore: { setMessages },
		activeChatStore,
	} = useStores();

	const { getActiveChat } = storeToRefs(activeChatStore);

	const error: Ref<string> = ref("");

	try {
		const multiData: IApiResponsePayload<IApiResponseMulti> = await useOFetch<IApiResponsePayload<IApiResponseMulti>>(api.multiDetail(agentId || getActiveChat.value?.agent), {
			method: "GET",
		});

		const { events, messages } = multiData.data;

		if (useIsActiveAgent(agentId || (getActiveChat.value && getActiveChat.value.agent))) {
			setMessages(messages);
			setEvents(filterAcceptedEvents(events));
		}

		saveToCache({
			agentId: agentId || getActiveChat.value?.agent || "",
			events,
			messages,
		});
	} catch (e) {
		const fetchError = e as IFetchError;

		error.value = fetchError.data?.message ?? fetchError.message ?? "Ошибка мульти запроса";
	}

	return { error };
}
