import dayjs from "dayjs";
import { default as locale } from "dayjs/locale/ru";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);

export default defineNuxtPlugin(() => {
	dayjs.locale(locale, null, false);
	dayjs.tz.setDefault("Europe/Moscow");
	return {
		provide: {
			dayjs,
		},
	};
});
