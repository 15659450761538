import type { IWsResponse } from "@/assets/ts/entities/ws/interfaces/IWsResponse";
import type { IAiGenerateMessage } from "@/assets/ts/entities/ai/interfaces/IAiGenerateMessage";

export function wsHandleGenerateMessage(response: IWsResponse<IAiGenerateMessage>) {
	const message: string = response.data.message;
	const {
		messageStore: { setMessage },
		generateMessageStore: { setAiGeneratesMessage, setAiGeneratedSuccess },
		modalErrorStore: { open: showError },
	} = useStores();

	if (message) {
		if (useIsActiveAgent(response.agent)) {
			setMessage(message);
		}

		setAiGeneratedSuccess(true);
	} else {
		setAiGeneratedSuccess(false);
		showError(`${response.data}`, true);
	}

	setAiGeneratesMessage(false);
}
