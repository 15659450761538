import { default as indexKmcoTUV9BfMeta } from "/opt/docker/pages/chat/[id]/index.vue?macro=true";
import { default as indexyT9cIalUxvMeta } from "/opt/docker/pages/chat/index.vue?macro=true";
import { default as indexsWiwm9unDIMeta } from "/opt/docker/pages/index.vue?macro=true";
import { default as indexFPCGuHxY2VMeta } from "/opt/docker/pages/token/index.vue?macro=true";
export default [
  {
    name: "chat-id",
    path: "/chat/:id()",
    meta: indexKmcoTUV9BfMeta || {},
    component: () => import("/opt/docker/pages/chat/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "chat",
    path: "/chat",
    component: () => import("/opt/docker/pages/chat/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/docker/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "token",
    path: "/token",
    meta: indexFPCGuHxY2VMeta || {},
    component: () => import("/opt/docker/pages/token/index.vue").then(m => m.default || m)
  }
]