import { useRuntimeConfig } from "nuxt/app";

export const useFetchOptions = () => {
	const {
		public: { REST_API_BROWSER_BASE_URL, DISP_APP_BASE_URL },
	} = useRuntimeConfig();
	const { data: session }: { data: any } = useAuth();

	let baseURL = REST_API_BROWSER_BASE_URL;
	if (typeof process !== "undefined" && process?.server) baseURL = DISP_APP_BASE_URL;

	return { baseURL, session };
};
