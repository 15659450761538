import { useFetch } from "nuxt/app";
import { useFetchOptions } from "~/composables/useFetchOptions";

export const $useFetch = <DataT = any, ErrorT = any>(request: string | Request | Ref<string | Request> | (() => string), opts: object) => {
	const { baseURL, session } = useFetchOptions();
	return useFetch<DataT, ErrorT>(request, {
		baseURL,
		headers: {
			Authorization: `Bearer ${session.value?.user?.accessToken}`,
		},
		...opts,
	});
};
