import { useFetchOptions } from "~/composables/useFetchOptions";

export const useOFetch = <Data>(request: string, opts: object) => {
	const { baseURL, session } = useFetchOptions();

	return $fetch<Data>(request, {
		baseURL,
		headers: {
			Authorization: `Bearer ${session.value?.user?.accessToken}`,
		},
		...opts,
	});
};
