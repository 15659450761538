import type { IWsResponse } from "@/assets/ts/entities/ws/interfaces/IWsResponse";
import type { IAiGenerateMessage } from "@/assets/ts/entities/ai/interfaces/IAiGenerateMessage";
import type { TAiResponseUpdateAll } from "@/assets/ts/entities/ai/types/TAiResponseUpdateAll";
import { wsHandleGenerateMessage } from "@/assets/ts/entities/ws/functions/ws-handle-response/ws-handle-generate-message";
import { wsHandleUpdateAll } from "@/assets/ts/entities/ws/functions/ws-handle-response/ws-handle-update-all";
import { wsHandleGenerateError } from "@/assets/ts/entities/ws/functions/ws-handle-response/ws-handle-generate-error";
import type { TWsResponseCommands } from "@/assets/ts/entities/ws/types/commands/TWsResponseCommands";
import type { IWsResponseError } from "@/assets/ts/entities/ws/interfaces/IWsResponseError";
import type { IAiGenerateMessageData } from "@/assets/ts/entities/ai/interfaces/IAiGenerateMessageData";
import type { IWsResponseUpdateAll } from "@/assets/ts/entities/ws/interfaces/IWsResponseUpdateAll";

export const wsCommandHandlers: Record<TWsResponseCommands, (response: any) => Promise<void> | void> = {
	generate_message: (response: IWsResponse<IAiGenerateMessage>) => wsHandleGenerateMessage(response),
	update_all: async (response: IWsResponseUpdateAll<TAiResponseUpdateAll>) => await wsHandleUpdateAll(response),
	generate_error: (response: IWsResponseError<IWsResponse<IAiGenerateMessageData>>) => wsHandleGenerateError(response),
};
