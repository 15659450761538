import type { IWsResponseError } from "@/assets/ts/entities/ws/interfaces/IWsResponseError";
import type { IWsResponse } from "@/assets/ts/entities/ws/interfaces/IWsResponse";
import type { IAiGenerateMessageData } from "@/assets/ts/entities/ai/interfaces/IAiGenerateMessageData";

export function wsHandleGenerateError(response: IWsResponseError<IWsResponse<IAiGenerateMessageData>>): void {
	const {
		generateMessageStore: { setAiGeneratesMessage, setAiGeneratedSuccess },
		modalErrorStore: { open: showError },
	} = useStores();

	setAiGeneratedSuccess(false);
	setAiGeneratesMessage(false);
	showError(response.error, true);
}
