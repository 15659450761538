import { defineStore } from "pinia";
import { type IChatItem } from "@/assets/ts/global/interfaces/chat/IChatItem";

export const useActiveChatStore = defineStore("active-chat", {
	state: (): {
		activeChat: null | IChatItem;
		activeChatId: string;
	} => ({
		activeChat: null,
		activeChatId: "",
	}),
	actions: {
		setActiveChat(chat: IChatItem) {
			this.activeChat = chat;

			this.setActiveChatId(this.activeChat.agent);
		},
		setActiveChatId(id: string) {
			this.activeChatId = id;
		},
	},
	getters: {
		getActiveChat(): null | IChatItem {
			return this.activeChat;
		},
		getActiveChatId(): string {
			return this.activeChatId;
		},
	},
});
