import { defineStore } from "pinia";

export const useGenerateMessageStore = defineStore("generate-message", {
	state: (): {
		aiGeneratesMessage: boolean;
		aiGeneratedSuccess: boolean;
	} => ({
		aiGeneratesMessage: false,
		aiGeneratedSuccess: false,
	}),
	actions: {
		aiGenerateMessage(agent: string, event: string, offer: string) {
			const {
				wsStore: { wsSend },
			} = useStores();

			wsSend<"generate_message">({
				command: "generate_message",
				data: {
					agent,
					event,
					offer,
				},
			});

			this.setAiGeneratesMessage(true);
		},
		setAiGeneratesMessage(status: boolean): void {
			this.aiGeneratesMessage = status;
		},
		setAiGeneratedSuccess(status: boolean): void {
			this.aiGeneratedSuccess = status;
		},
	},
	getters: {
		isAiGenerateMessage(): boolean {
			return this.aiGeneratesMessage;
		},
		isAiGeneratedSuccess(): boolean {
			return this.aiGeneratedSuccess;
		},
	},
});
